import styled, { css } from 'styled-components';
// components
import BaseButton from './Base';
// styles
import { palette, onlyHover } from '../styles';

const OutlineButton = styled(BaseButton)`
  border: 1px solid
    ${({ color, disabled }) => {
      if (!disabled) {
        if (color === 'Red') {
          return palette.red040;
        }
        if (color === 'Grey') {
          return palette.grey050;
        }
      }

      if (disabled) {
        return palette.grey030;
      }

      return color;
    }};

  background-color: ${palette.white};

  color: ${({ color, disabled }) => {
    if (!disabled) {
      if (color === 'Red') {
        return palette.red040;
      }
      if (color === 'Grey') {
        return palette.grey060;
      }
    }

    if (disabled) {
      return palette.grey030;
    }

    return color;
  }};

  &::before {
    background-color: rgba(0, 0, 0, 0);
  }

  ${onlyHover} {
    &:hover {
      ${({ disabled }) =>
        !disabled &&
        css`
          &::before {
            background-color: rgba(0, 0, 0, 0.04);
          }
        `};
    }
  }

  &:active {
    ${({ disabled }) =>
      !disabled &&
      css`
        &::before {
          background-color: rgba(0, 0, 0, 0.04);
        }
      `};
  }
`;

export default OutlineButton;
