import styled from 'styled-components';
// styles
import { mediaQuery, zIndex } from 'cds/styles';

/**
 * 상단 헤더 레이아웃 (Mobile, PC 구분)
 * @param {object} props
 * @param {JSX.Element} props.mobile
 * @param {JSX.Element} props.pc
 *
 * @example
 * <HeadLayout
 *   mobile={<Mobile_Header />}
 *   pc={<PC_Header />}
 * />
 */
const HeadLayout = ({ mobile, pc }) => {
  return (
    <Layout>
      <div>{mobile}</div>
      <div>{pc}</div>
    </Layout>
  );
};

const Layout = styled.div`
  position: relative;
  z-index: ${zIndex.header};

  & > div:first-child {
    display: block;
  }

  & > div:last-child {
    display: none;
  }

  ${mediaQuery.large} {
    display: block;

    & > div:first-child {
      display: none;
    }

    & > div:last-child {
      display: block;
    }
  }
`;

export default HeadLayout;
